.popup__input {
  width: 100%;
  height: 27px;
  padding: 0;
  padding-bottom: 7px;
  box-sizing: border-box;
  /* C помощью padding подогнал расположение текста в input */
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  /* В фигме указан 17px, но фактический размер области = 14px, а не 17px */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.popup__input::placeholder {
  /* color: #000000; */
  color: #c4c4c4;
}

.popup__input:focus {
  outline-style: none;
}

@media screen and (max-width: 500px) {
  .popup__input {
    max-width: 100%;
  }
}

.popup__input_error {
  color: #ee3465;
  border-bottom: 1px solid #ee3465;
}

.popup__span-error {
  position: absolute;
  top: 29px;
  left: 0;
  color: #ee3465;
}
