.sign__label {
  position: relative;
  display: block;
  width: 100%;
  height: 27px;
  box-sizing: border-box;
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
}

.sign__input {
  width: 100%;
  height: 27px;
  box-sizing: border-box;
  padding: 0;
  padding-bottom: 7px;
  background-color: #000000;
  border: none;
  border-bottom: 2px solid #cccccc;
  color: #cccccc;
}

.sign__label:last-of-type {
  margin-bottom: 216px;
}

.sign__input::placeholder {
  color: #cccccc;
}

.sign__input:focus {
  outline: none;
}

.sign__input_error {
  color: #ee3465;
  border-bottom: 2px solid #ee3465;
}

.sign__span-error {
  position: absolute;
  top: 29px;
  left: 0;
  color: #ee3465;
}

@media screen and (max-width: 424px) {
  .sign__label:last-of-type {
    margin-bottom: 171px;
  }
}
