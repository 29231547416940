.header__link {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
  color: #ffffff;
  border: none;
  background-color: transparent;
  text-decoration: none;
}

@media screen and (max-width: 424px) {
  .header__link {
    font-size: 14px;
    line-height: 17px;
    margin-right: 14px;
  }
}
