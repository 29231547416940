@media screen and (max-width: 767px) {
  .header__burger_active {
    background-image: url(../../../../images/close-icon.svg);
    background-size: cover;
    margin-top: 4.5px;
    margin-right: 30px;
    height: 20px;
    width: 20px;
  }
}

@media screen and (min-width: 767px) {
  .header__burger_active {
    display: none;
  }
}
