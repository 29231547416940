.popup__container {
  position: relative;
  box-sizing: border-box;
  max-width: 430px;
  height: 330px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

@media screen and (max-width: 500px) {
  .popup__container {
    max-width: calc(100% - 38px);
    /* height: 322px; */
  }
}
