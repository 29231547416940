.profile__add-button {
  padding: 0;
  margin: 0 0 0 auto;
  width: 150px;
  height: 50px;
  background-color: #000000;
  border: 2px solid #ffffff;
  border-radius: 2px;
}

@media screen and (max-width: 767px) {
  .profile__add-button {
    margin: 0 auto;
    width: 282px;
  }
}

@media screen and (max-width: 360px) {
  .profile__add-button {
    max-width: 100%;
  }
}
