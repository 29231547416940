.sign__title {
  margin: 0 auto 50px;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
}

@media screen and (max-width: 424px) {
  .sign__title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 40px;
  }
}
