.popup__input-container {
  position: relative;
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
}

.popup__input-container:last-of-type {
  margin-bottom: 48px;
}

@media screen and (max-width: 500px) {
  .popup__input-container:last-of-type {
    margin-bottom: 45px;
  }
}
