.elements__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px 17px;
}

@media screen and (max-width: 900px) {
  .elements__container {
    margin: 0 30px;
  }
}
