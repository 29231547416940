.sign {
  position: relative;
  margin: 0 auto 80px;
  padding-top: 20px;
  max-width: 358px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 424px) {
  .sign {
    padding-top: 0;
    max-width: 260px;
  }
}
