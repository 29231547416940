.header__burger {
  margin: 4px 30px 0 0;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  width: 24px;
  height: 21px;
}

.header__burger span {
  height: 3px;
  width: 100%;
  background-color: #ffffff;
  transform: scale(1);
}

.header__burger::before {
  content: "";
  position: absolute;
  top: 0;
  height: 3px;
  width: 100%;
  background-color: #ffffff;
  transition: all 1s ease 0;
}

.header__burger::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 3px;
  width: 100%;
  background-color: #ffffff;
  transition: all 1s ease 0;
}

@media screen and (max-width: 767px) {
  .header__burger {
    display: flex;
  }
}

@media screen and (min-width: 767px) {
  .header__burger {
    display: none;
  }
}
