.page {
  margin: 0 auto;
  padding: 0;
  max-width: 1280px;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  background-color: #000000;
  color: #ffffff;
}

@media screen and (max-width: 930px) {
  .page {
    /* padding: 0 5%; */
  }
}

@media screen and (max-width: 424px) {
  .page {
    margin: 0 auto;
  }
}

html {
  background-color: #000000;
}
