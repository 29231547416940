.profile__avatar-container {
  position: relative;
  margin: 0;
  margin-right: 30px;
  width: 120px;
  height: 120px;
}

@media screen and (max-width: 767px) {
  .profile__avatar-container {
    margin: 0 auto 26px;
  }
}
