.popup__title-infoToolTip {
  margin: 0;
  margin-bottom: 60px;
  max-width: 358px;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #000000;
}

@media screen and (max-width: 424px) {
  .popup__title-infoToolTip {
    max-width: 244px;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
    margin-bottom: 50px;
  }
}
