.popup__close-icon {
  all: unset;
  position: absolute;
  /* padding: 0; */
  width: 32px;
  height: 32px;
  right: -40px;
  top: -40px;
  /* border: 0; */
  /* background-color: transparent; */
}

@media screen and (max-width: 510px) {
  .popup__close-icon {
    width: 20px;
    height: 20px;
    right: 0;
    top: -36px;
  }
}
