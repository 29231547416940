.burgerMenu__onSignOut {
  margin: 0 auto;
  padding: 0;
  padding-bottom: 40px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #a9a9a9;
  border: none;
  background-color: transparent;
  text-decoration: none;
}
