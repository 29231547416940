/* Локально подключаем шрифт 'Inter' (на сайте используем normal, 400, 500, 900) */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url(Inter-Regular.woff) format('woff');
  src: url(Inter-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url(Inter-Medium.woff) format('woff');
  src: url(Inter-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: url(Inter-Black.woff) format('woff');
  src: url(Inter-Black.woff2) format('woff2');
}
