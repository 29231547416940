.elements__title {
  margin: 0;
  margin-top: 6px;
  font-weight: 900;
  font-size: 24px;
  line-height: 1;
  color: #000000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
