.profile__edit-avatar {
	position: absolute;
	top: 0;
	left: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	background-image: url(../../../images/edit_avatar.svg);
	/* background-image: url(../../../../public/images/edit_avatar.svg); */

	background-color: rgba(0, 0, 0, 0.8);
	background-repeat: no-repeat;
	background-position: center;
	border: none;
	border-radius: 50%;
	opacity: 0;
	transition: opacity 1s;
	cursor: pointer;
}

.profile__edit-avatar:hover {
	opacity: 1;
}