.popup__input-error_active {
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  right: 0;
  padding-top: 5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: red;
  border-top: 1px solid #FF0000;
  opacity: 1;
  visibility: visible;
}
