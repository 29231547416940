.popup__form {
  margin: 0 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 500px) {
  .popup__form {
    max-width: calc(100% - 44px);
  }
}
