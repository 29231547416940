.popup__title {
  margin: 34px 64px 54px 36px;
  width: 330px;
  color: #000000;
  font-weight: 900;
  font-size: 24px;
  line-height: 1;
  /* В фигме указан 29px, но фактический размер области = 23px, а не 29px */
}

@media screen and (max-width: 500px) {
  .popup__title {
    max-width: 238px;
    margin: 25px 22px 75px;
    font-size: 18px;
    line-height: 22px;
  }
}
