.footer {
  margin: 0 auto;
  padding-bottom: 60px;
  max-width: 880px;
}

@media screen and (max-width: 424px) {
  .footer {
    max-width: calc(100% - 38px);
    padding-bottom: 36px;
  }
}
