.header__container {
  margin: 0;
  margin-top: 6px;
  display: flex;
  flex-direction: row;
  gap: 24px;
}

@media screen and (max-width: 900px) {
  .header__container {
    margin-right: 30px;
  }
}

@media screen and (max-width: 768px) {
  .header__container {
    display: none;
  }
}
